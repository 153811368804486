import React from "react";
import {Image, Dropdown} from 'react-bootstrap'
import "./NavigationBar.css"
import SiteLogo from "./../../Assets/Images/ezytrack-logo.png"

const DropdownToggleStyle = {
    background: "rgba(0,0,0,0)",
    border: "none",
    height: "100%",
    width: "100%"
};

class NavigationBar extends React.Component {

    constructor(props) {
        super(props);

        this.componentRefs = {
            dashboardLink: React.createRef(),
            timeLink: React.createRef(),
            teamLink: React.createRef(),
            actionItemsLink: React.createRef(),
            reportsLink: React.createRef()
        };

        let currentUserFirstName = null;
        let currentUserFirstInitial = null;
        let currentUserLastInitial = null;
        if (props["profile-service"].getCurrentUserProfile() !== null) {
            currentUserFirstName = props["profile-service"].getCurrentUserProfile().basicInfo.firstName;
            currentUserLastInitial = props["profile-service"].getCurrentUserProfile().basicInfo.lastName[0];
            currentUserFirstInitial = currentUserFirstName[0];
        }

        let currentUserEmail = null;
        if (props["auth-service"].getUserEmail() !== null) {
            currentUserEmail = props["auth-service"].getUserEmail();
        }

        this.state = {
            currentUserFirstName: currentUserFirstName,
            currentUserEmail: currentUserEmail,
            currentUserInitials: `${currentUserFirstInitial}${currentUserLastInitial}`.toUpperCase()
        };
    }

    onLinkClicked(linkRef) {
        if(!linkRef) { return; }
        for (const refKey of Object.keys(this.componentRefs)) {
            const ref = this.componentRefs[refKey];
            if (ref === linkRef || linkRef === null) {
                continue;
            }
            const refClassesSet = new Set(linkRef.current.className.split(" "));
            refClassesSet.delete("link-current");
            ref.current.className = Array.from(refClassesSet).join(" ");
        }
        const linkClassesSet = new Set(linkRef.current.className.split(" "));
        if (!linkClassesSet.has("link-current")) {
            linkClassesSet.add("link-current");
        }
        linkRef.current.className = Array.from(linkClassesSet).join(" ");
    }

    onLogoutClicked() {
        if (this.props["logout-action"] && typeof this.props["logout-action"] === "function") {
            this.props["logout-action"]();
        }
    }

    renderProfileContainer() {
        const containerStyle = {
            display: "flex",
            justifyContent: "center"
        };

        const messageContainerStyle = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "12px"
        };

        const initialsContainer = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        };

        return <div style={containerStyle}>
            <div style={messageContainerStyle}>
                <div>Hey {this.state.currentUserFirstName}!</div>
                <div>{this.state.currentUserEmail}</div>
            </div>
            <div style={initialsContainer}>
                <div>
                    <div style={{
                        borderRadius: 1000,
                        background: "red",
                        width: 64,
                        height: 64,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: 32,
                        letterSpacing: 1.5
                    }}>
                        {this.state.currentUserInitials}
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        const imageStyle = {
            display: "block",
            maxWidth: "200px",
            width: "auto"
        };
        return <div id={"header-container"}>
            <div id={"navigation-bar-main-container"} >
                <img alt="" style={imageStyle} src={SiteLogo}/>
                <Dropdown style={{height: "100%"}}>
                    <Dropdown.Toggle id={"nav-bar-profile-toggle"}
                                     className={"caret-off"}
                                     style={DropdownToggleStyle}>
                        {this.renderProfileContainer()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{width: "100%"}}>
                        <Dropdown.Item onClick={() => {
                            this.onLogoutClicked()
                        }}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    }
}

export default NavigationBar
