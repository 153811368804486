import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const icoStyle = {
    fontFamily: "Font Awesome",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "rgb(84, 92, 119)",
    fontSize: "20px"
};

const labelStyle = {
    fontFamily: "Montserrat",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: "18px",
    color: "rgb(84, 92, 119)",
    // "textAlign": "left",
    padding: 0
};


class LabeledInputField extends React.Component {

    constructor() {
        super();

        this.fieldRef = React.createRef();
    }

    onInputChanged() {
        if (this.props.onChange && typeof this.props.onChange === "function") {
            this.props.onChange(this.fieldRef.current.value);
        }
    }

    render() {
        const icoText = this.props.icoText ?? "null";
        const labelText = this.props.labelText ?? "null";
        return (
            <Container>
                <Row>
                    <Col style={labelStyle} xs={12}>
                        <span style={icoStyle}>{icoText}</span> {labelText}
                    </Col>
                </Row>
                <Row>
                    <input
                        ref={this.fieldRef}
                        onChange={() => {
                            this.onInputChanged()
                        }}
                        className={"input-text-box"}
                        type={this.props.type ?? ""} />
                </Row>
            </Container>
        )
    }
}

export default LabeledInputField;
