export default function generateDateTimeEle(forDate, includeSeconds) {
    let date = forDate;
    if (typeof date === "string") {
        date = new Date(date);
    }

    let monthStr = String(date.getMonth() + 1);
    if (monthStr.length === 1) {
        monthStr = `0${monthStr}`;
    }

    let dayStr = String(date.getDate());
    if (dayStr.length === 1) {
        dayStr = `0${dayStr}`;
    }

    let yearStr = String(date.getFullYear()).substr(2);

    const dateStr = `${monthStr}/${dayStr}/${yearStr}`;

    let hourStr = String(date.getHours());
    if (hourStr.length === 1) {
        hourStr = `0${hourStr}`
    }

    let minStr = String(date.getMinutes());
    if (minStr.length === 1) {
        minStr = `0${minStr}`
    }

    let secsStr = String(date.getSeconds());
    if (secsStr.length === 1) {
        secsStr = `0${secsStr}`
    }

    let timeStr = `${hourStr}:${minStr}`;
    if (includeSeconds) {
        timeStr += `:${secsStr}`
    }

    return `${dateStr} ${timeStr}`;
}
