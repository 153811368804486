import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./EmployeeActivityTable.css"


class EmployeeActivityTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedEmployeeKey: null,
            selectedEmployeeIdx: null
        };

        this.generateEmployeeSubTable = this.generateEmployeeSubTable.bind(this);
    }

    onTimesheetClicked(idx) {
        if (this.props["onRowClicked"] && typeof this.props["onRowClicked"] === "function") {
            this.props["onRowClicked"](this.props["data-employees"][this.state.selectedEmployeeIdx].timesheets[idx].route);
        }
    }

    onEmployeeClicked(idx) {
        let newSelectedEmployeeId;
        if (this.state.selectedEmployeeIdx === this.props["data-employees"][idx]["_id"]) {
            newSelectedEmployeeId = null;
        } else {
            newSelectedEmployeeId = this.props["data-employees"][idx]["_id"];
        }
        this.setState({
            selectedEmployeeKey: newSelectedEmployeeId,
            selectedEmployeeIdx: idx
        });
    }

    generateEmployeeSubTable(forEmployee, employeeIdx) {
        function generateDateTimeEle(forDate) {
            let date = forDate;
            if (typeof date === "string") {
                date = new Date(date);
            }

            let monthStr = String(date.getMonth() + 1);
            if (monthStr.length === 1) {
                monthStr = `0${monthStr}`;
            }

            let dayStr = String(date.getDate());
            if (dayStr.length === 1) {
                dayStr = `0${dayStr}`;
            }

            let yearStr = String(date.getFullYear()).substr(2);

            const dateStr = `${monthStr}/${dayStr}/${yearStr}`;

            let hourStr = String(date.getHours());
            if (hourStr.length === 1) {
                hourStr = `0${hourStr}`
            }

            let minStr = String(date.getMinutes());
            if (minStr.length === 1) {
                minStr = `0${minStr}`
            }

            const timeStr = `${hourStr}:${minStr}`;

            return (
                <div className={"table-cell"}>
                    {dateStr} {timeStr}
                </div>
            );
        }

        function generateDurationEle(forDate) {
            const date = Number(forDate);
            const hour = Math.floor(date / 3600);
            const month = Math.floor(date % 3600 / 60);
            const hourStr = hour > 0 ? hour + "h" : "";
            const minStr = month > 0 ? month + "m" : "";
            let delimiter = "";
            if (hourStr.length > 0 && minStr.length > 0) {
                delimiter = ", ";
            }
            let timeStr = `${hourStr}${delimiter}${minStr}`;
            if (timeStr.length === 0) {
                timeStr = "0m";
            }

            return (
                <div className={"table-cell"}>
                    {`${timeStr}`}
                </div>
            );
        }

        function generateSortedTimesheets(fromTimesheetsArr) {
            return fromTimesheetsArr.sort((last, next) => {
                const lastDate = new Date(last.inTime);
                const nextDate = new Date(next.inTime);
                if (lastDate < nextDate) {
                    return 1
                } else {
                    return -1
                }
            });
        }

        const generateTableRow = (name, employeeIdx, timesheetIdx, inTime, outTime, duration, opts = {}) => {
            const rowClasses = [];

            if (this.state.selectedEmployeeIdx === employeeIdx) {
                rowClasses.push("table-row");
            } else {
                rowClasses.push("table-row-collapsed");
            }

            const rowClass = rowClasses.join(" ");
            return (
                <Row onClick={() => {
                    this.onTimesheetClicked(timesheetIdx)
                }} className={rowClass}>
                    <Col className={"table-cell"} xs={3}>
                        {name}
                    </Col>
                    <Col className={"table-cell"} xs={3}>
                        {inTime}
                    </Col>
                    <Col className={"table-cell"} xs={3}>
                        {outTime}
                    </Col>
                    <Col className={"table-cell"} xs={3}>
                        {duration}
                    </Col>
                </Row>
            );
        };

        function generateTimesheetRow(employeeName, employeeIdx, timesheetIdx, timesheet, opts = {}) {
            const newestTimesheet = timesheet;
            const newestTimesheetInDate = new Date(newestTimesheet.inTime) / 1000;
            let newestTimesheetOutDate;
            if (newestTimesheet.outTime) {
                newestTimesheetOutDate = new Date(newestTimesheet.outTime) / 1000;
            } else {
                newestTimesheetOutDate = new Date() / 1000;
            }
            const newestTimesheetDateDiff = newestTimesheetOutDate - newestTimesheetInDate;
            const newestTimesheetDuration = generateDurationEle(newestTimesheetDateDiff);

            return generateTableRow(
                employeeName,
                employeeIdx,
                timesheetIdx,
                generateDateTimeEle(newestTimesheet.inTime),
                newestTimesheet.outTime ? generateDateTimeEle(newestTimesheet.outTime) : "",
                newestTimesheetDuration,
                opts);
        }

        const rows = [];
        const timesheets = generateSortedTimesheets(forEmployee.timesheets);
        if (timesheets.length === 0) {
            return (<></>);
        }

        //TODO: BETTER ROW ID THAN THE _id FIELD! DON'T EXPOSE THAT MORE THAN NEEDED!
        rows.push(
            <Container>
                <Row key={forEmployee["_id"]} className={"table-row"}
                    onClick={() => {
                        this.onEmployeeClicked(employeeIdx)
                    }}>
                    <Col xs={3}>
                        <strong>{`${forEmployee.profile.basicInfo.firstName} ${forEmployee.profile.basicInfo.lastName}`}</strong>
                    </Col>
                </Row>
                <Row>
                    <hr style={{ marginBottom: 0 }} />
                </Row>
            </Container>
        );

        timesheets.forEach((timesheet, timesheetIdx) => {
            rows.push(generateTimesheetRow("", employeeIdx, timesheetIdx, timesheet, { hiddenOnLoad: true }))
        });

        return rows;
    }

    generateEmployeeTable() {
        const rows = [];
        rows.push(
            <Container >
                <Row className={"table-row"} style={{ cursor: "default" }}>
                    <Col className={"table-cell"} xs={3}>
                        <strong>Employee Name</strong>
                    </Col>
                    <Col className={"table-cell"} xs={3}>
                        <strong>In</strong>
                    </Col>
                    <Col className={"table-cell"} xs={3}>
                        <strong>Out</strong>
                    </Col>
                    <Col className={"table-cell"} xs={3}>
                        <strong>Total</strong>
                    </Col>
                </Row>
                <Row>
                    <hr style={{ marginBottom: 0 }} />
                </Row>
            </Container>
        );

        this.props["data-employees"].forEach((data, idx) => {
            rows.push(this.generateEmployeeSubTable(data, idx));
        });

        return rows
    }

    render() {
        return this.generateEmployeeTable()
    }
}

export default EmployeeActivityTable
