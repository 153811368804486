//TODO: Reset range control track when route is switched through table

import React from 'react';
import mapboxgl from '!mapbox-gl';
import { Col, Container, Row } from "react-bootstrap";
import "./MapView.css"
import generateDateTimeStr from "./../../Utility/generateDateTimeString"

mapboxgl.accessToken = 'pk.eyJ1IjoiaWx1YWRtaW4iLCJhIjoiY2tia3VqMmRqMHMwdDMxcWwxZnJ3Y3ozZiJ9.3Fql5QNQwYz-dMjiGbEsyw';

class MapView extends React.Component {

    constructor(props) {
        super(props);
        this.rangeControlRef = React.createRef();
        this.timestampLabelRef = React.createRef();
        this.mapContainer = React.createRef();
        this.map = null;
        this.routeMarker = null;
        this.currentPlaybackPosition = 0;
        this.currentTimeout = null;
        this.state = {
            isPlaying: false
        };

        this.onPlaybackRangeChanged = this.onPlaybackRangeChanged.bind(this);
        this.onPlaybackToggled = this.onPlaybackToggled.bind(this);
    }

    componentDidMount() {
        const { lng, lat, zoom, mapStyleUrn } = this.props["data-initialSettings"];
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: mapStyleUrn,
            center: [lng, lat],
            zoom: zoom
        });

        map.on("load", () => {
            this.map = map;
            this.updateMapRoute()
        });
    }

    componentWillUnmount() {
        this.map = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props["data-route"] !== prevProps["data-route"]) {
            if (this.currentTimeout) {
                clearTimeout(this.currentTimeout);
                this.currentTimeout = null;
            }
            this.rangeControlRef.current.value = 0;
            this.currentPlaybackPosition = 0;
            this.setState({
                isPlaying: false
            }, () => {
                this.updateMapRoute();
            });
        }
    }

    getMapBoundsForRoute() {
        const route = this.props["data-route"];

        const coordinates = route.geometry.coordinates;

        const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
        );

        for (const coord of coordinates) {
            bounds.extend(coord);
        }

        if (this.routeMarker) {
            bounds.extend(this.routeMarker.getLngLat())
        }

        return bounds;
    }

    updateMapRoute() {
        if (this.map.getLayer("route")) {
            this.map.removeLayer('route');
        }
        if (this.map.getSource("route")) {
            this.map.removeSource('route');
        }


        if (!this.props["data-route"]) {
            return;
        }

        const route = this.props["data-route"];

        this.map.addSource('route', {
            type: "geojson",
            data: route
        });
        this.map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
                "line-join": "round",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "blue",
                "line-width": 8
            }
        });

        if (this.routeMarker) {
            this.routeMarker.remove();
            this.routeMarker = null
        }
        const firstCoordinate = route.geometry.coordinates[0];
        const firstTimestamp = route.properties.timestamps[0] * 1000;
        const marker = new mapboxgl.Marker();
        marker.setLngLat(firstCoordinate);
        marker.addTo(this.map);
        this.routeMarker = marker;
        this.timestampLabelRef.current.innerText = generateDateTimeStr(new Date(firstTimestamp), true);

        const bounds = this.getMapBoundsForRoute();
        this.map.fitBounds(bounds, {
            padding: 40
        });
    }

    updateMarkerLocationForMapAnimation() {
        this.currentTimeout = setTimeout(() => {
            const coord = this.props["data-route"].geometry.coordinates[this.currentPlaybackPosition];
            const timestamp = new Date(this.props["data-route"].properties.timestamps[this.currentPlaybackPosition] * 1000);

            this.currentPlaybackPosition++;
            this.routeMarker.setLngLat(coord);
            this.timestampLabelRef.current.innerText = generateDateTimeStr(timestamp, true);
            this.rangeControlRef.current.value = this.currentPlaybackPosition - 1;

            if (this.currentPlaybackPosition < this.props["data-route"].geometry.coordinates.length) {
                this.updateMarkerLocationForMapAnimation()
            } else {
                clearTimeout(this.currentTimeout);
                this.currentTimeout = null;
                this.setState({
                    isPlaying: !this.state.isPlaying
                })
            }
        }, 1000)
    }

    updatePlaybackAnimation() {
        if (this.state.isPlaying) {
            if (this.currentTimeout) {
                return;
            }
            this.updateMarkerLocationForMapAnimation()
        } else {
            clearTimeout(this.currentTimeout);
            this.currentTimeout = null;
            this.currentPlaybackPosition = 0;
        }
    }

    onPlaybackRangeChanged(evt) {
        const coord = this.props["data-route"].geometry.coordinates[evt.target.value];
        const timestamp = this.props["data-route"].properties.timestamps[evt.target.value] * 1000;

        if (this.routeMarker) {
            this.routeMarker.setLngLat(coord);
        } else {
            const marker = new mapboxgl.Marker();
            marker.setLngLat(coord);
            marker.addTo(this.map);
            this.routeMarker = marker;
        }

        if (this.state.isPlaying) {
            clearTimeout(this.currentTimeout);
            this.currentTimeout = null;
            this.currentPlaybackPosition = 0;
            this.setState({
                isPlaying: !this.state.isPlaying
            })
        }

        this.timestampLabelRef.current.innerText = generateDateTimeStr(new Date(timestamp), true);
        this.currentPlaybackPosition = evt.target.value;
    }

    onPlaybackToggled() {
        this.setState({
            isPlaying: !this.state.isPlaying
        }, () => {
            this.updatePlaybackAnimation()
        })
    }

    renderPlaybackRangeControl() {
        if (!this.props["data-route"]) {
            return;
        }
        const max = this.props["data-route"].geometry.coordinates.length - 1;
        return (
            <div id={"playback-range-container"}>
                <input id={"playback-range"}
                    defaultValue={0}
                    type={"range"}
                    min={0}
                    max={max}
                    ref={this.rangeControlRef}
                    onChange={this.onPlaybackRangeChanged} />
            </div>
        );
    }

    renderPlaybackToggleControls() {
        if (!this.props["data-route"]) {
            return;
        }
        let faText = this.state.isPlaying ? "pause-circle" : "play-circle";
        return (
            <span style={{ fontSize: 32 }} id={"play-button"}
                onClick={this.onPlaybackToggled}>
                {faText}
            </span>
        );
    }

    renderPlaybackTimestamp() {
        if (!this.props["data-route"]) {
            return;
        }

        return (
            <span style={{ width: "auto" }} ref={this.timestampLabelRef}
                id={"timestamp-indicator"}></span>
        )
    }

    render() {
        return (
            <Container style={{ padding: "0px", height: "100%" }} id={"map-container"}>
                <Row style={{ height: "100%" }}>
                    <Col style={{ padding: "0px" }}>
                        <div ref={this.mapContainer} className="map-container"
                            style={{ height: "100%" }}></div>
                    </Col>
                </Row>
                <Row id={"playback-control-container-top"}>
                    <Col>
                        <Row>
                            <Col style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {this.renderPlaybackToggleControls()}
                            </Col>
                            <Col md={11} style={{ padding: 0 }}>
                                <Container>
                                    <Row>
                                        {this.renderPlaybackRangeControl()}
                                    </Row>
                                    <Row style={{ display: "flex", justifyContent: "center" }}>
                                        {this.renderPlaybackTimestamp()}
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default MapView
