import { Component, createRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EmployeeActivityTable from "../../Components/EmployeeActivityTable/EmployeeActivityTable";
import MapView from "../../Components/MapView/MapView";
import APIConfig from "./../../Config/EZyTrackApiConfig";
import Axios from "axios";
import LoadingWheel from "../../Components/LoadingWheel/LoadingWheel";
import "./DailyActivityPage.css"

const initialMapSettings = {
    lng: -84.49344635009766,
    lat: 38.04734756126993,
    zoom: 10,
    mapStyleUrn: "mapbox://styles/mapbox/streets-v11"
};

class DailyActivityScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentRoute: null,
            employeeData: [],
            dateRange: [Math.floor(new Date().getTime() / 1000) - 604800, Math.floor(new Date().getTime() / 1000)],
            isLoadingDataFromApi: true
        };

        this.startDateRangeControlRef = createRef(null);
        this.endDateRangeControlRef = createRef(null);

        this.onTableRowClicked = this.onTableRowClicked.bind(this);
        this.renderEmployeeTable = this.renderEmployeeTable.bind(this);
    }

    async componentDidMount() {
        const startDateTimeRange = new Date(this.state.dateRange[0] * 1000);
        const endDateTimeRange = new Date(this.state.dateRange[1] * 1000);

        const startYear = String(startDateTimeRange.getFullYear());
        const endYear = String(endDateTimeRange.getFullYear());

        let startMonth = String(startDateTimeRange.getMonth() + 1);
        let endMonth = String(endDateTimeRange.getMonth() + 1);
        if (startMonth.length === 1) {
            startMonth = `0${startMonth}`;
        }
        if (endMonth.length === 1) {
            endMonth = `0${endMonth}`;
        }

        let startDay = String(startDateTimeRange.getDate());
        let endDay = String(endDateTimeRange.getDate());
        if (startDay.length === 1) {
            startDay = `0${startDay}`;
        }
        if (endDay.length === 1) {
            endDay = `0${endDay}`;
        }


        let startHour = String(startDateTimeRange.getHours());
        let endHour = String(endDateTimeRange.getHours());
        if (startHour.length === 1) {
            startHour = `0${startHour}`;
        }
        if (endHour.length === 1) {
            endHour = `0${endHour}`;
        }

        let startMinutes = String(startDateTimeRange.getMinutes());
        let endMinutes = String(endDateTimeRange.getMinutes());
        if (startMinutes.length === 1) {
            startMinutes = `0${startMinutes}`;
        }
        if (endMinutes.length === 1) {
            endMinutes = `0${endMinutes}`;
        }


        const startDateStr = `${startYear}-${startMonth}-${startDay}`;
        const endDateStr = `${endYear}-${endMonth}-${endDay}`;
        const startTimeStr = `${startHour}:${startMinutes}`;
        const endTimeStr = `${endHour}:${endMinutes}`;


        const startDateTimeStr = `${startDateStr}T${startTimeStr}`;
        const endDateTimeStr = `${endDateStr}T${endTimeStr}`;
        this.startDateRangeControlRef.current.value = startDateTimeStr;
        this.endDateRangeControlRef.current.value = endDateTimeStr;

        await this.updateData(this.state.dateRange);
        this.setState({
            isLoadingDataFromApi: false
        });
    }

    async getUserProfile(forUser = null) {
        let url = APIConfig.Urls.Profiles.createProfilesUrl(this.props.authService.getCompanyCode());
        if (forUser) {
            url = `${url}?forUser=${forUser}`
        }
        const response = await Axios({
            method: "get",
            url: url,
            config: {
                "headers": {
                    "X-Client-Platform": "manager-portal",
                    "Content-Type": "application/json"
                },
                "withCredentials": true
            },
            headers: {
                "Authorization": `JWT ${this.props.authService.getToken()}`
            }
        })
        return response.data;
    }

    async updateData(dateRange) {
        const team = await this.getTeams();
        const employeeData = [];
        let includeActiveTimesheet = true;
        for (const member of team) {
            const memberProfile = await this.getUserProfile(member);
            const activeTimesheet = (await this.getActiveTimesheet(member))[0];
            if (activeTimesheet) {
                const inTime = Math.floor(new Date(activeTimesheet.inTime).getTime() / 1000);
                //Only display active timesheet if it falls within the entered
                // datetime range.
                if (dateRange[0] <= inTime) {
                    activeTimesheet.route = (await this.getRouteForTimesheet(activeTimesheet));
                    const activeTimesheetRoutePoints = [];
                    const activeTimesheetRouteTimestamps = [];
                    for (const point of activeTimesheet.route) {
                        const coords = point.geometry.coordinates;
                        activeTimesheetRoutePoints.push(coords);
                        activeTimesheetRouteTimestamps.push(point.properties.timestamp);
                    }
                    activeTimesheet.route = {
                        type: "Feature",
                        properties: {
                            timestamps: activeTimesheetRouteTimestamps
                        },
                        geometry: {
                            type: "LineString",
                            coordinates: activeTimesheetRoutePoints
                        }
                    };
                } else {
                    includeActiveTimesheet = false;
                }
            }

            const inactiveTimesheetsMinTime = dateRange[0];
            const inactiveTimesheetsMaxTime = dateRange[1];
            const inactiveTimesheets = await this.getInactiveTimesheets(member, inactiveTimesheetsMinTime, inactiveTimesheetsMaxTime);
            for (const timesheet of inactiveTimesheets) {
                timesheet.route = await this.getRouteForTimesheet(timesheet);
                const routePoints = [];
                const routeTimestamps = [];
                for (const point of timesheet.route) {
                    const coords = point.geometry.coordinates;
                    routePoints.push(coords);
                    routeTimestamps.push(point.properties.timestamp);
                }
                timesheet.route = {
                    type: "Feature",
                    properties: {
                        timestamps: routeTimestamps
                    },
                    geometry: {
                        type: "LineString",
                        coordinates: routePoints
                    }
                }
            }

            let finalTimesheets = [];
            if (activeTimesheet && includeActiveTimesheet) {
                finalTimesheets.push(activeTimesheet);
            }
            if (Array.isArray(inactiveTimesheets) && inactiveTimesheets.length > 0) {
                finalTimesheets = finalTimesheets.concat(inactiveTimesheets);
            }
            employeeData.push({
                _id: member,
                name: member,
                timesheets: finalTimesheets,
                profile: memberProfile
            });
        }

        await this.setState({
            employeeData: employeeData
        });
    }

    async getTeams() {
        const url = APIConfig.Urls.Team.createTeamUrl(this.props.authService.getCompanyCode());
        const response = await Axios({
            method: "get",
            url: url,
            config: {
                "headers": {
                    "X-Client-Platform": "manager-portal",
                    "Content-Type": "application/json"
                },
                "withCredentials": true
            },
            headers: {
                "Authorization": `JWT ${this.props.authService.getToken()}`
            }
        });

        return response.data;
    }

    async getActiveTimesheet(forUser) {
        const url = APIConfig.Urls.Timesheets.createTimesheetUrl(this.props.authService.getCompanyCode());
        let activeTimesheetUrl = `${url}?activeOnly=true&hydrate=true`;
        if (forUser) {
            activeTimesheetUrl += "&forUser=" + forUser;
        }
        const response = await Axios({
            method: "get",
            url: activeTimesheetUrl,
            config: {
                "headers": {
                    "X-Client-Platform": "manager-portal",
                    "Content-Type": "application/json"
                },
                "withCredentials": true
            },
            headers: {
                "Authorization": `JWT ${this.props.authService.getToken()}`
            }
        });

        return response.data.timesheets;
    }

    async getInactiveTimesheets(forUser, minTime, maxTime) {
        const url = APIConfig.Urls.Timesheets.createTimesheetUrl(this.props.authService.getCompanyCode());
        let inactiveTimesheetUrl = `${url}?inactiveOnly=true&hydrate=true`;

        if (forUser) {
            inactiveTimesheetUrl += "&forUser=" + forUser;
        }

        if (minTime) {
            inactiveTimesheetUrl += "&minTime=" + (minTime * 1000);
        }

        if (maxTime) {
            inactiveTimesheetUrl += "&maxTime=" + (maxTime * 1000);
        }

        const response = await Axios({
            method: "get",
            url: inactiveTimesheetUrl,
            config: {
                "headers": {
                    "X-Client-Platform": "manager-portal",
                    "Content-Type": "application/json"
                },
                "withCredentials": true
            },
            headers: {
                "Authorization": `JWT ${this.props.authService.getToken()}`
            }
        });

        return response.data.timesheets;
    }

    async getRouteForTimesheet(timesheet) {
        if (!timesheet) {
            throw new Error("NULL TIMESHEET");
        }
        const forUser = timesheet.owner;
        const inTime = Math.floor((new Date(timesheet.inTime).getTime() / 1000));
        let outTime;
        if (timesheet.outTime) {
            outTime = Math.floor((new Date(timesheet.outTime).getTime() / 1000));
        }
        let url = `${APIConfig.Urls.Routes.createRoutesUrl(this.props.authService.getCompanyCode())}/points?forUser=${forUser}&startTime=${inTime}`;
        if (outTime) {
            url += "&endTime=" + outTime;
        }
        const response = await Axios({
            method: "get",
            url: url,
            config: {
                "headers": {
                    "X-Client-Platform": "manager-portal",
                    "Content-Type": "application/json"
                },
                "withCredentials": true
            },
            headers: {
                "Authorization": `JWT ${this.props.authService.getToken()}`
            }
        });

        return response.data;
    }

    onTableRowClicked(route) {
        this.setState({
            currentRoute: route
        })
    }

    async onUpdateButtonClicked() {
        await this.setState({
            dateRange: [Math.floor(new Date(this.startDateRangeControlRef.current.value).getTime() / 1000), Math.floor(new Date(this.endDateRangeControlRef.current.value).getTime() / 1000)],
            isLoadingDataFromApi: true
        });
        await this.updateData(this.state.dateRange);
        this.setState({
            isLoadingDataFromApi: false
        });
    }

    renderEmployeeTable() {
        let employeeTableContent = null;

        const determineTotalTimesheetCount = function () {
            let count = 0;
            for (const employeeData of this.state.employeeData) {
                count += employeeData.timesheets.length;
            }
            return count;
        }.bind(this);

        function generateLoadingWheelContent() {
            return <Container style={{ padding: 0 }}>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col id={"loading-wheel-col"}
                        xs={12}>
                        <LoadingWheel />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }} id={"loading-text"} xs={12}>
                        Retrieving Data...
                    </Col>
                </Row>
            </Container>
        }

        if (this.state.isLoadingDataFromApi) {
            employeeTableContent = generateLoadingWheelContent();
        } else if (determineTotalTimesheetCount() === 0) {
            employeeTableContent =
                <Container style={{ padding: 0 }}>
                    <Row >
                        <Col style={{ display: "flex", justifyContent: "center" }} id={"no-timesheets-found-header"} xs={12}>
                            No Timesheets Found
                        </Col>
                    </Row>
                    <Row >
                        <Col style={{ display: "flex", justifyContent: "center" }} id={"no-timesheets-found-text"} xs={12}>
                            Please adjust date and time range and try again.
                        </Col>
                    </Row>
                </Container>


        } else {
            employeeTableContent = <Col xs={12}>
                <EmployeeActivityTable
                    data-employees={this.state.employeeData}
                    data-current-idx={this.state.currentEmployeeIndex}
                    onRowClicked={this.onTableRowClicked} />
            </Col>
        }

        return employeeTableContent;
    }

    renderDateRangeControls() {

        const startIconStyle = {
            color: "rgb(38, 90, 167)",
            fontFamily: "Font Awesome",
            fontWeight: "bold"
        };

        const endIconStyle = {
            color: "rgb(38, 90, 167)",
            fontFamily: "Font Awesome",
            fontWeight: "normal"
        };

        const labelStyle = {
            textAlign: "left",
            width: "50%",
            fontFamily: "Montserrat",
            textTransform: "uppercase",
            fontSize: "26px",
            fontWeight: "bold",
            color: "rgb(38, 90, 167)"
        };

        const inputStyle = {
            marginBottom: "24px",
            width: "50%"
        };

        const updateButtonColContainer = {
            // marginTop: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start"
        };

        const updateButtonStyle = {
            cursor: "pointer",
            background: "rgb(38, 90, 167)",
            border: "none",
            width: "50%",
            minHeight: "32px",
            borderRadius: 4,
            boxShadow: "0px 2px rgba(0,0,0,0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        };

        const updateButtonTextStyle = {
            textTransform: "uppercase",
            "color": "white",
            "fontFamily": "Montserrat",
            "fontWeight": 600
        };

        return <Container style={{ padding: 0, marginBottom: "24px" }}>
            <Row >
                <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }} md={12}>
                    <label style={labelStyle} htmlFor="start-date"><span style={startIconStyle}>clock</span> Start</label>
                    <input style={inputStyle} ref={this.startDateRangeControlRef} type="datetime-local"
                        id="start-date"
                        name="start-date" />
                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }} md={12}>
                    <label style={labelStyle} htmlFor="end-date"><span style={endIconStyle}>clock</span> End</label>
                    <input style={inputStyle} ref={this.endDateRangeControlRef} type="datetime-local"
                        id="end-date"
                        name="end-date" />
                </Col>
            </Row>
            <Row>
                <Col style={updateButtonColContainer} xs={12}>
                    <div style={updateButtonStyle} onClick={() => {
                        this.onUpdateButtonClicked()
                    }}>
                        <span style={updateButtonTextStyle}>Update</span>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    renderMapView() {
        return <MapView
            data-route={this.state.currentRoute}
            data-initialSettings={initialMapSettings} />
    }

    render() {
        return (
            <div>
                <Container id={"dap-container"} style={{ position: "absolute", left: 0, marginTop: "64px", padding: "0px", width: "50%", height: "100%" }} fluid={true}>
                    <Row>
                        <Col style={{ display: "flex", alignItems: "center" }} md={12}>
                            <Container>
                                <Row id={"daily-activity-header-container"}>
                                    <h1 style={{ fontFamily: "Montserrat" }}>Daily Activity</h1>
                                </Row>
                                <Row id={"date-range-table-controls"}>
                                    {this.renderDateRangeControls()}
                                </Row>
                                <Row id={"employee-table"}>
                                    <Col style={{ padding: 0 }}>
                                        {this.renderEmployeeTable()}
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                <div style={{ position: "absolute", right: 0, marginTop: "64px", height: "50%", width: "50%" }}>
                    {this.renderMapView()}
                </div>
            </div>
        )
    }
}

export default DailyActivityScreen;