import APIConfig from "./../../Config/EZyTrackApiConfig";
import Axios from "axios";

export default class EzyApiAuthService {
    constructor() {
        this._token = "";
        this._companyCode = "";
        this._userEmail = "";

        const token = sessionStorage.getItem("token");
        const companyCode = sessionStorage.getItem("companyCode");
        const userEmail = sessionStorage.getItem("userEmail");

        if (token && typeof token === "string" && companyCode && typeof companyCode === "string" && userEmail && typeof userEmail === "string") {
            this._token = token;
            this._companyCode = companyCode;
            this._userEmail = userEmail;
        }
    }

    async authorizeWithCredentials(companyCode, email, password) {
        const url = APIConfig.Urls.Sessions.createSessionUrl(companyCode),
            data = {
                "email": email,
                "password": password
            },
            config = {
                "headers": {
                    "X-Client-Platform": "manager-portal",
                    "Content-Type": "application/json"
                },
                "withCredentials": true
            };

        const response = await Axios.post(
            url,
            data,
            config
        );

        if (!response || !response.data || !response.data.token) {
            throw new Error("Invalid response received.")
        }

        this._token = response.data.token;
        this._companyCode = companyCode;
        this._userEmail = email;
        sessionStorage.setItem("token", this._token);
        sessionStorage.setItem("companyCode", this._companyCode);
        sessionStorage.setItem("userEmail", this._userEmail);
    }

    isAuthorized() {
        return this._token !== "" && this._companyCode !== "";
    }

    getCompanyCode() {
        return this._companyCode;
    }

    getToken() {
        return this._token;
    }

    getUserEmail() {
        return this._userEmail;
    }

    logout() {
        if (sessionStorage.getItem("token")) {
            sessionStorage.removeItem("token");
        }

        if (sessionStorage.getItem("companyCode")) {
            sessionStorage.removeItem("companyCode");
        }

        if (sessionStorage.getItem("userEmail")) {
            sessionStorage.removeItem("userEmail");
        }

        this._token = "";
        this._companyCode = "";
        this._userEmail = "";
    }
}
