import {Component} from "react";
import DailyActivityPage from "../../Pages/DailyActivityPage/DailyActivityPage";
import LoginPage from "../../Pages/Login/LoginPage";
import NavigationBar from "../../Components/NavigationBar/NavigationBar";
import ProfileService from "../../Services/ProfileService/ProfileService";
import MobileUnsupportedPage
    from "../../Pages/MobileUnsupportedPage/MobileUnsupportedPage";

export default class NavigationManager extends Component {
    constructor() {
        super();

        this.state = {
            authorized: false,
            windowWidth: window.innerWidth
        };
        this.profileService = new ProfileService();

        this.onSuccessfulLogin = this.onSuccessfulLogin.bind(this);
        this.onLogoutRequested = this.onLogoutRequested.bind(this);
    }

    componentDidMount() {
        window.onresize = () => {
            this.setState({
                windowWidth: window.innerWidth
            });
        };
        if (this.props.authService.isAuthorized()) {
            this.setState({
                authorized: true
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authService !== this.props.authService) {
            if (this.props.authService.isAuthorized()) {
                this.setState({
                    authorized: true
                });
            } else {
                this.setState({
                    authorized: false
                });
            }
        }
    }

    async onSuccessfulLogin() {
        const token = this.props.authService.getToken();
        const companyCode = this.props.authService.getCompanyCode();
        await this.profileService.retrieveAndStoreCurrentUserProfileFromAPI(token, companyCode);

        if (this.props.authService.isAuthorized()) {
            this.setState({
                authorized: true
            });

        } else {
            this.setState({
                authorized: false
            })
        }
    }

    onLogoutRequested() {
        this.profileService.logout();
        this.props.authService.logout();
        sessionStorage.clear();
        this.setState({
            authorized: false
        });
    }

    render() {
        let screen;

        if (this.state.windowWidth < 768) {
            return <MobileUnsupportedPage/>
        }

        if (this.state.authorized) {
            screen = <>
                <header>
                    <NavigationBar auth-service={this.props.authService}
                                   profile-service={this.profileService}
                                   logout-action={this.onLogoutRequested}/>
                </header>
                <DailyActivityPage authService={this.props.authService}/>
            </>
        } else {
            screen = <LoginPage authService={this.props.authService}
                                onSuccessfulLogin={this.onSuccessfulLogin}/>
        }

        return screen;
    }
}
