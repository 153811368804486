import APIConfig from "./../../Config/EZyTrackApiConfig"
import Axios from "axios"

export default class ProfileService {
    constructor() {
        this._currentUserProfile = null;

        if (sessionStorage.getItem("currentUserProfile")) {
            this._currentUserProfile = JSON.parse(sessionStorage.getItem("currentUserProfile"));
        }
    }

    getCurrentUserProfile() {
        return (this._currentUserProfile)
    }

    async retrieveAndStoreCurrentUserProfileFromAPI(withToken, withCompanyCode) {
        const url = APIConfig.Urls.Profiles.createProfilesUrl(withCompanyCode);
        const response = await Axios({
            method: "get",
            url: url,
            config: {
                "headers": {
                    "X-Client-Platform": "manager-portal",
                    "Content-Type": "application/json"
                },
                "withCredentials": true
            },
            headers: {
                "Authorization": `JWT ${withToken}`
            }
        });

        this._currentUserProfile = response.data;
        sessionStorage.setItem("currentUserProfile", JSON.stringify(this._currentUserProfile));
    }

    logout() {
        if (sessionStorage.getItem("currentUserProfile")) {
            sessionStorage.removeItem("currentUserProfile");
        }
        this._currentUserProfile = null;
    }
}
