import { Component } from "react";
import "./MobileUnsupportedPage.css"
import logo from "./../../Assets/Images/ezytrack-logo.png";

export default class MobileUnsupportedPage extends Component {
    constructor() {
        super();
    }

    render() {
        return <div id="mobile-unsupported" style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundSize: "cover" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "white", borderRadius: "8px", padding: 16, width: "80%" }}>
                <img style={{ marginBottom: "16px", maxWidth: "100%" }} src={logo} alt="EZyTrack" />
                <h1 style={{ marginBottom: "16px" }}>Mobile Version Coming Soon!</h1>
                <p>We are working on the mobile version of the EZyTrack portal, and hope to bring it to you soon! In the meantime, you can access the portal from a desktop device.</p>
            </div>
        </div>
    }
}