import {Component} from "react"
import "./LoadingWheel.css"

export default class LoadingWheel extends Component {
    render() {
        return <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    }
}
