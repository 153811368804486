import React from 'react';
import './App.css';
import EzyApiAuthService from './Services/AuthService/EzyApiAuthService';
import NavigationManager from "./Managers/NavigationManager/NavigationManager";

const authService = new EzyApiAuthService();

function App() {
	return <div className='App' >
		<NavigationManager authService={authService} />
	</div>;
}

export default App;
