const apiProtocol = "https";
// const apiHostname = "bbush.local.api.ezytrack.dev.ilunlimited.com";
const apiHostname = "api.ezytrack.net";
const apiPort = 443;
const apiVersion = 0;


/* DO NOT MODIFY BELOW THIS LINE! */
const apiUrl = `${apiProtocol}://${apiHostname}:${apiPort}/v${apiVersion}`;

const Endpoints = {
    /**
     * @type SessionEndpoints
     * Contains endpoints related to session authentication and token management.
     * @property {function(string):string} createSessionUrl Generates the URL for the
     *  session endpoint.
     */
    Sessions: {
        /**
         * Generates the URL for the sessions endpoint based on the passed company code.
         * @param {string} `withCompanyCode` The company code used to identify the
         *  appropriate session endpoint.
         * @returns {string} The URL for the sessions endpoint.
         * @throws {Error} If the value passed for parameter `withCompanyCode` is not a
         *  [`string`]{@link String} or empty.
         */
        createSessionUrl: function createSessionUrl(withCompanyCode) {
            if (!withCompanyCode) {
                throw new Error("Cannot create session url without a company code.");
            }
            return `${apiUrl}/${withCompanyCode}/sessions`;
        }
    },
    Timesheets: {
        createTimesheetUrl: function createTimesheetUrl(withCompanyCode) {
            if (!withCompanyCode) {
                throw new Error("Cannot create timesheet url without a company code.");
            }
            return `${apiUrl}/${withCompanyCode}/timesheets`;
        }
    },
    Team: {
        createTeamUrl: function createTeamUrl(withCompanyCode) {
            if (!withCompanyCode) {
                throw new Error("Cannot create team url without a company code.");
            }
            return `${apiUrl}/${withCompanyCode}/team`;
        }
    },
    Routes: {
        createRoutesUrl: function createRoutesUrl(withCompanyCode) {
            if (!withCompanyCode) {
                throw new Error("Cannot create routes url without a company code.");
            }
            return `${apiUrl}/${withCompanyCode}/routes`;
        }
    },
    Profiles: {
        createProfilesUrl: function createProfilesUrl(withCompanyCode) {
            if (!withCompanyCode) {
                throw new Error("Cannot create profiles url without a company code.");
            }
            return `${apiUrl}/${withCompanyCode}/profiles`;
        }
    }
}

const obj = {
    Urls: Endpoints
}

export default obj;
