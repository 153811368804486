import "./LoginScreen.css";
import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import logo from "./../../Assets/Images/ezytrack-logo.png";
import LabeledInputField from "../../Components/LabeledInputField/LabeledInputField";
import LoginFlairImg from "../../Assets/Images/login-flair-compressed.webp";
import {fadeIn, fadeOut} from "react-animations";
import styled, {keyframes} from "styled-components";

const FadeOutButton = styled.button`animation: 0.25s ${keyframes`${fadeOut}`} forwards`;
const FadeInButton = styled.button`animation: 0.25s ${keyframes`${fadeIn}`} forwards`;

class LoginScreen extends React.Component {

    constructor(props) {

        super(props);

        this.email = "";
        this.password = "";
        this.companyCode = "";
        this.isAttemptingLogin = false;

        this.onCompanyCodeChanged = this.onCompanyCodeChanged.bind(this);
        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onSignInButtonClicked = this.onSignInButtonClicked.bind(this);
        this.onAnimationFinished = this.onAnimationFinished.bind(this);

        this.state = {
            // Possible States: hidden, showing, fading-in, fading-out
            signInButtonState: "showing"
        }
    }

    onCompanyCodeChanged(newValue) {

        this.companyCode = newValue;

    }

    onEmailChanged(newValue) {

        this.email = newValue;

    }

    onPasswordChanged(newValue) {

        this.password = newValue;

    }

    onAnimationFinished() {
        this.isAnimating = false;
    }

    async onSignInButtonClicked() {
        if (this.isAttemptingLogin) {
            return;
        }
        this.isAttemptingLogin = true;

        this.isAnimating = true;

        await this.setState({
            signInButtonState: "fading-out",
        });
        while (this.isAnimating) {
            await new Promise(resolve => {
                setTimeout(() => {
                    resolve()
                }, 100);
            });
        }
        await this.setState({
            signInButtonState: "hidden"
        });

        try {
            if (!this.props.onSuccessfulLogin || typeof this.props.onSuccessfulLogin !== "function") {
                throw new Error("No onSuccessfulLogin handler installed")
            }
            await this.props.authService.authorizeWithCredentials(this.companyCode, this.email, this.password);
            this.props.onSuccessfulLogin();

        } catch (err) {
            alert("We're sorry, but we could not log you in at this time. Please try" +
                " again later.");
            this.isAnimating = true;
            await this.setState({
                signInButtonState: "fading-in"
            });
            while (this.isAnimating) {
                await new Promise(resolve => {
                    setTimeout(() => {
                        resolve()
                    }, 100);
                })
            }
            await this.setState({
                signInButtonState: "showing"
            });
        } finally {
            this.isAttemptingLogin = false;
        }
    }

    renderSignInButton() {
        switch (this.state.signInButtonState) {
            case "showing":
                return <button id={"sign-in-button"}
                               onClick={this.onSignInButtonClicked}>Sign In</button>
                break;
            case "fading-out":
                return <FadeOutButton onAnimationEnd={this.onAnimationFinished}
                                      id={"sign-in-button"}
                                      onClick={this.onSignInButtonClicked}>Sign
                    In</FadeOutButton>
                break;
            case "fading-in":
                return <FadeInButton onAnimationEnd={this.onAnimationFinished}
                                     id={"sign-in-button"}
                                     onClick={this.onSignInButtonClicked}>Sign
                    In</FadeInButton>
                break;
            case "hidden":
                return <div></div>
                break;
            default:
                return <div></div>
                break;
        }
    }

    render() {
        return <Container fluid id={"login-screen-container"}>
            <Row>
                <Col id={"left-container"}>
                    <img id={"login-flair-img"} src={LoginFlairImg} alt={""}/>
                </Col>
                <Col sm={12} md={6} id={"right-container"}>
                    <Container style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                    }} id={"form-container"} fluid>
                        <Row>
                            <Col id="logo-container">
                                <img id={"ezytrack-logo-img"} src={logo}
                                     alt={"Welcome to EZyTrack"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col id={"sign-in-text"} xs={12}>
                                Sign In
                            </Col>
                        </Row>
                        <Row style={{minWidth: "350px"}}>
                            <Col xs={12} style={{"marginBottom": "12px"}}>
                                <LabeledInputField icoText={"building"}
                                                   onChange={this.onCompanyCodeChanged}
                                                   labelText={"Company Code"}
                                                   type={"text"}/>
                            </Col>
                        </Row>
                        <Row style={{minWidth: "350px"}}>
                            <Col style={{"marginBottom": "12px"}} xs={12}>
                                <LabeledInputField icoText={"envelope"}
                                                   onChange={this.onEmailChanged}
                                                   labelText={"Email"}
                                                   type={"email"}/>
                            </Col>
                        </Row>
                        <Row style={{minWidth: "350px"}}>
                            <Col style={{"marginBottom": "24px"}} xs={12}>
                                <LabeledInputField class="input-field" icoText={"lock"}
                                                   onChange={this.onPasswordChanged}
                                                   labelText={"Password"}
                                                   type={"password"}/>
                            </Col>
                        </Row>
                        <Row style={{minWidth: "350px"}}>
                            <Col id={"sign-in-button-container"}>
                                {this.renderSignInButton()}
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>;

    }

}

export default LoginScreen;
